<template>
  <div class="px-8 py-10 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-4">
        <div style="position: relative;width: 100%;">
          <img
            src="@/assets/RiskManagement/water.jpg"
            width="706"
            height="568"
            alt=""
            title="water"
            class="img-responsive wp-image-12039"
          />
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-4">
        <TitleComponent
          style="padding-bottom:1rem;"
          title="Environmental Services"
          size="large"
        />
        <Paragraph
          ><p>
            SMS provides a wide range of environmental services in compliance
            with the Resource Conservation and Recovery Act, Clean Air Act,
            National Emissions Standards for Hazardous Air Pollutants (NESHAP),
            and Clean Water Act. These services include:
          </p>
          <ul class="links">
            <li>
              Hazardous Waste Characterization and Management for both hazardous
              and non-hazardous wastes (RCRA)
            </li>
            <li>
              Lead- Based Paint and Asbestos sampling coordination and guidance
              (NESHAP)
            </li>
            <li>
              Non-Title V and Title V Air Permit application, application
              renewal, and record-management/compliance
            </li>
            <li>Annual Emissions Inventory submittal guidance</li>
            <li>
              Stormwater Pollution Prevention Plan implementation and submittal
            </li>
            <li>Wastewater Treatment compliance reporting</li>
          </ul>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-4">
        <h3 class="text-xl">Hazardous Waste Characterizations</h3>
        <Paragraph
          ><p>
            SMS can assist clients categorize various wastes following
            guidelines mandated by Resource Conservation and Recovery Act Title
            40 Code of Federal Regulations Parts 260 through 273. Hazardous
            Waste Characterization services include:
          </p>
          <ul class="links">
            <li>
              Determining whether the waste is categorized as Hazardous or
              Non-Hazardous using Parts 261.
            </li>
            <li>
              Documenting point of generation for the wastes.
            </li>
            <li>
              Identifying the frequency of generation.
            </li>
            <li>
              Identifying if the material is a hazardous waste based on
              ignitability, corrosivity, reactivity, and toxicity.
            </li>
            <li>
              Providing guidance and recommendations for proper disposal in
              accordance to Title 40 Code of Federal Regulations Parts 262.
            </li>
          </ul>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-4 pl-8">
        <div style="position: relative;width: 100%;">
          <img
            src="@/assets/RiskManagement/hwc1.png"
            width="600"
            height="400"
            alt=""
            title="rcra"
            class="img-responsive wp-image-12039"
          />
          <Paragraph
            >More info here:
            <a
              href="https://www.epa.gov/laws-regulations/summary-resource-conservation-and-recovery-act"
              target="_blank"
              >https://www.epa.gov/laws-regulations/summary-resource-conservation-and-recovery-act</a
            ></Paragraph
          >
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-4">
        <h3 class="text-xl">Non-Title V and Title V Permitting Services</h3>
        <Paragraph
          ><p>
            SMS can provide guidance and assistance for both Title V and
            Non-Title V facilities in accordance with the Clean Air Act Title 40
            Code of Federal Regulations Parts 70. Assistance with these services
            includes:
          </p>
          <ul class="links">
            <li>
              Annual Emission Inventory submittal through The Environmental
              Protection Agencies Shared CROMERR platform.
            </li>
            <li>
              Non-Title V and Title V Permit application, permit renewal, and
              compliance in accordance to your state’s guidelines.
            </li>
          </ul>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-4 pl-8">
        <div style="position: relative;width: 100%;">
          <img
            src="@/assets/RiskManagement/cleanair.png"
            width="600"
            height="400"
            alt=""
            title="clean air act"
            class="img-responsive wp-image-12039"
          />
          <Paragraph
            >Additional info here:
            <a
              href="https://www.epa.gov/laws-regulations/summary-clean-air-act"
              target="_blank"
              >https://www.epa.gov/laws-regulations/summary-clean-air-act</a
            ></Paragraph
          >
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-4">
        <h3 class="text-xl">Lead Based Paint and Asbestos Sampling</h3>
        <Paragraph
          ><p>
            SMS has experience and knowledge to provide guidance for performing
            lead-based paint inspections in accordance with Title 40 Code of
            Federal Regulations Parts 745 Lead Based Paint Poisoning Prevention
            in Certain Residential Structures and U.S Department of Housing and
            Urban Development guidelines. SMS also has knowledge on asbestos
            sampling in accordance to Title 40 Code of Federal Regulations Parts
            763.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-4 pl-8">
        <div style="position: relative;width: 100%;">
          <img
            src="@/assets/RiskManagement/leadpaint.png"
            width="600"
            height="400"
            alt=""
            title="lead paint risks"
            class="img-responsive wp-image-12039"
          />
          <Paragraph
            >Additional info here:
            <a href="https://www.epa.gov/lead" target="_blank"
              >https://www.epa.gov/lead</a
            ></Paragraph
          >
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-4">
        <h3 class="text-xl">Stormwater Pollution Prevention Pollution Plan</h3>
        <Paragraph
          ><p>
            SMS can aid with developing a Stormwater Pollution Prevention Plan
            in accordance with the National Pollutant Discharge Elimination
            System, a regulatory program outlined by the Clean Water Act.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-4 pl-8">
        <div style="position: relative;width: 100%;">
          <img
            src="@/assets/RiskManagement/stormwat.png"
            width="600"
            height="400"
            alt=""
            title="lead paint risks"
            class="img-responsive wp-image-12039"
          />
          <Paragraph
            >Additional info here:
            <a
              href="https://www.epa.gov/laws-regulations/history-clean-water-act"
              target="_blank"
              >https://www.epa.gov/laws-regulations/history-clean-water-act</a
            >
            and here:
            <a href="https://www.epa.gov/npdes" target="_blank"
              >https://www.epa.gov/npdes.</a
            ></Paragraph
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Environmental Services",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS provides a wide range of environmental services in compliance with RCRA, NESHAP, Title V, Clean Air and Water regulations. Storm water expertise as well as hazardous waste consulting services provided."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

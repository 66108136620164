<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="General Health and Safety" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full mb-6 md:pr-2">
        <Paragraph
          ><p>
            SMS personnel have extensive experience in the many different
            aspects of Health and Safety. From developing site health and safety
            standards to auditing standards against regulatory requirements and
            best management practices. SMS personnel have expertise with Health
            and Safety items, including but not limited, to the following:
          </p>
          <ul class="links">
            <li>Job Safety Analysis (JSA)</li>
            <li>Job Hazard Analysis (JHA) (Risk Ranking)</li>
            <li>
              Life Critical Program Development and Inspections
              <ul class="links">
                <li>Confined Space Entry</li>
                <li>Lock/Tag/Try</li>
                <li>Line Breaking</li>
                <li>Hot Work</li>
              </ul>
            </li>
            <li>PPE Assessments</li>
            <li>Arc Flash Hazard Analysis</li>
            <li>Machine Guarding Risk Assessments</li>
            <li>Industrial Hygiene Site Assessment and Monitoring Plans</li>
            <li>
              Radiation Hazard Assessments
              <ul class="links">
                <li>Radioactive Materials</li>
                <li>X-Ray</li>
                <li>Laser</li>
              </ul>
            </li>
            <li>Fall Protection Risk Assessment</li>
            <li>Flammable Gas and Liquid Risk Assessments</li>
            <li>
              Electrical Classification Assessment and Electrical Classification
              Drawings
            </li>
            <li>
              Voluntary Protection Program (VPP) Assistance and Gap Analysis
            </li>
          </ul>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pl-2"></div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "General Health and Safety",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS has extensive experience in developing and evaluating many different aspects of Health and Safety. "
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
